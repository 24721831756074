<template>
	<div id="product">
        <div class="top" v-if="product.top_image">
            <img v-bind:src="product.top_image" alt="Top image">
        </div>
		<lazy-hydrate when-idle>
			<div class="top" v-if="product.video">
				<Video
					id="product-video"
					:params="{ background: 1, allowfullscreen: 0, autoplay: 1 }"
					:src="product.video"
				/>
			</div>
		</lazy-hydrate>
		<div class="sections bg-white">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div
							class="sections__menu flex align-items-center justify-content-between"
						>
							<div class="flex align-items-center">
								<h4
									class="sections__section pointer"
									v-for="(section, index) in productSections"
									:key="index"
									v-show="section.visible"
									v-scroll-to="{
                  					el: section.scrollTo,
                  					offset: displayWidth < 992 ? -128 : -60
                				}"
								>
									{{ section.name }}
								</h4>
							</div>
                            <div>
                                <product-configurator-button
                                    v-if="hasProductConfigurator && !isMobile && product.bee_product_id && !orderColor"
                                    :link="localePath(getProductConfigurationLink(product))"
                                />
                                <div v-else>
                                    <button v-if="query.so" @click="openMapPopup" class="small button">{{ $t('Register product') }}</button>
                                    <div else></div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
        <RegisterProjectPopup :coordinatesFinishedLoading="coordinatesFinishedLoading" :projectDetails="projectDetails" :orderInfo="orderInfo" :visible="showMapPopup" @close="showMapPopup = false"/>
		<div class="container">
			<div v-if="!orderInfo" class="row">
				<div class="col-md-12">
					<lazy-hydrate when-idle>
						<breadcrumbs class="desktop-only" :breadcrumbs="breadcrumbs"/>
					</lazy-hydrate>
				</div>
			</div>
			<div class="row pb-6">
				<div class="col-12">
					<lazy-hydrate>
						<h2>{{ productName }}</h2>
					</lazy-hydrate>
				</div>
				<div class="col-12">
					<div class="row min-height-base">
						<div class="col-12 col-lg-4 product-overview">
							<div>
								<h3>{{ $t('Product Overview') }}</h3>
								<lazy-hydrate>
									<sf-loader :loading="productDataIsLoading">
										<HTMLContent v-if="productDescription" :content="productDescription" tag="p">
											{{ productDescription }}
										</HTMLContent>
									</sf-loader>
								</lazy-hydrate>
								<lazy-hydrate when-idle>
									<product-colours
										v-if="attributeSet !== 'Healthy Lighting'"
										:product="product"
										:order-color="orderColor"
										@goToBigImage="goToBigImage"
									/>
								</lazy-hydrate>
							</div>
                            <h3
                                v-if="productLabel"
                                class="product-new-label"
                            >
                                {{ productLabel }}
                            </h3>
						</div>
						<div class="col-12 col-lg-8 product-gallery">
							<lazy-hydrate when-visible>
								<sf-loader :loading="productDataIsLoading">
									<product-gallery
										ref="galleryTemplate"
										v-if="productGallery.length > 0"
										:product-name="getProductName(product)"
										:product-gallery="productGallery"
                                        :hasOneImage="hasOneImage"
									/>
								</sf-loader>
							</lazy-hydrate>
						</div>
					</div>
				</div>
			</div>
			<lazy-hydrate when-idle>
				<specification
					id="specifications"
					:description="productSpecificationDescription"
					:notes="productNotes"
					:labels="labels"
					:loading="loadingLabels"
					:productSku="sku"
					:order-info="orderInfo"
				/>
			</lazy-hydrate>
			<div class="container">
				<div class="row">
					<div class="col-12 border-top">
					</div>
				</div>
			</div>
			<Documents :productSku="sku" :hasOneImage="hasOneImage"/>
            <RegisteredLuminaires :salesOrderNumber="salesOrderNumber"/>
			<lazy-hydrate when-idle>
				<div class="hero" v-if="hero">
					<img
						v-if="hero.type === 'img'"
						:src="hero.url"
						:alt="getProductName(product)"
					>
					<div
						v-else
						class="Sirv"
						:data-src="hero.url"
					/>
				</div>
			</lazy-hydrate>
			<lazy-hydrate when-idle>
				<sf-loader :loading="productDataIsLoading">
					<FeatureCategory
						v-if="attributeSet !== 'Healthy Lighting' && featureCategories"
						class="mb-3 pt-4 min-height-large"
						id="features"
						:featureCategories="featureCategories"
						:productSku="sku"
					/>
				</sf-loader>
			</lazy-hydrate>
		</div>
		<ReferencesSlider id="references" :loading="loadingReferences" :references="references"/>
		<div class="container">
            <client-only>
    			<lazy-hydrate when-visible>
    				<Assortment
    					id="assortment"
    					class="mb-6"
    					v-if="abbreviation"
    					:product="product"
    					:loading="productDataIsLoading"
    				/>
    			</lazy-hydrate>
            </client-only>
			<Accessories
				v-if="attributeSet !== 'Healthy Lighting'"
				id="accessories"
				class="mb-6 pt-6 border-top"
				:productSku="sku"
			/>
			<div class="related-products-slider">
				<client-only>
					<lazy-hydrate when-visible>
						<ProductsCarousel
							:products="product.related_products"
							:loading="productDataIsLoading"
							:title="$t('Related Products')"
						/>
					</lazy-hydrate>
				</client-only>
			</div>
		</div>
	</div>
</template>
<script>
import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	useAsync,
	useRoute
} from '@nuxtjs/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs';
import FeatureCategory from '@/components/Product/FeatureCategory';
import Video from '@/components/Common/Video';
import productGetters, { getName as getProductName } from '@/getters/productGetters';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import cacheControl from '~/helpers/cacheControl';
import { SfLoader } from '@storefront-ui/vue';
import { useI18n } from '@/helpers/hooks/usei18n';
import { useProduct, useReferences, useLabels, useUiState } from '~/composables';
import { findActiveCategory } from '~/modules/catalog/category/helpers';
import isEmpty from 'lodash.isempty';
import ProductColours from '@/components/Product/ProductColours';
import ProductsCarousel from '~/components/ProductsCarousel';
import Accessories from '@/components/Product/Accessories';
import Documents from '@/components/Product/Documents';
import HTMLContent from '@/components/HTMLContent';
import ReferencesSlider from '@/modules/vizulo/references/ReferencesSlider.vue';
import ProductConfiguratorButton from '~/components/Common/ProductConfiguratorButton';
import Assortment from '@/components/Product/Assortment';
import ProductGallery from '@/components/Product/ProductGallery';
import Specification from '@/components/Product/Specification';
import useCategory from '~/modules/catalog/category/components/category/useCategory';
import RegisterProjectPopup from '~/components/Common/RegisterProjectPopup.vue';
import RegisteredLuminaires from '@/components/Product/RegisteredLuminaires.vue';

export default defineComponent({
	name: 'Product',
	components: {
		ProductConfiguratorButton,
		Specification,
		ProductColours,
		Assortment,
		ProductGallery,
		HTMLContent,
		Breadcrumbs,
		Video,
		FeatureCategory,
		SfLoader,
		Accessories,
		Documents,
		ReferencesSlider,
		ProductsCarousel,
        RegisterProjectPopup,
        RegisteredLuminaires,
	},
	props: {
		loading: Boolean,
		products: Object,
		sku: String,
		orderInfo: Object
	},
	middleware: cacheControl({
		'max-age': 36000,
		'stale-when-revalidate': 5,
		'reuse': true,
	}),
	head() {
		if (!this.product) {
			return null;
		}

		const title = this.product?.name ? this.product.name + ' - Vizulo' : 'Vizulo';
		const meta = [];
		let script = [];


		if (this.product.image_360) {
			script = [
				{
					src: 'https://scripts.sirv.com/sirvjs/v3/sirv.js',
					type: 'text/javascript',
					async: true,
					'data-components': 'spin, zoom, video'
				}
			];
		}

		return {
			title,
			meta,
			script
		};
	},
	setup(props) {
		const route = useRoute();
		const { path, query, params } = route.value;
		const { isMobile } = useUiState();
		const { addTags } = useCache();
		const { loading: productsLoading } = useProduct(props.sku);
		const { categoryTree, lastVisitedCategoryUrlPath } = useCategory();
		const productLabelsQuery = ref({ items: [], pageInfo: {}, totalCount: null });
		const { loadingLabels, getLabels } = useLabels();
		const { getReferences, loadingReferences } = useReferences();
		const references = ref([]);
		const windowWidth = ref(process.browser ? window.innerWidth : '');
		const onWidthChange = () => windowWidth.value = window.innerWidth;
		const displayWidth = computed(() => windowWidth.value);
        const hasOneImage = ref(false);
        const oneImageUrl = 'https://www.vizulo.com/media/catalog/category/custom_requests/MRS-png.png';
        const salesOrderNumber = computed(() => query.so ?? '');
        const serialNumber = computed(() => query.sn);
        const showMapPopup = ref(false);
        const coordinatesFinishedLoading = ref(false);

        const projectDetails = ref({
            salesOrderNumber: null,
            serialNumber: null,
            latitude: null,
            longitude: null,
            article: null
        })

		const product = computed(() => {
			return Array.isArray(props.products?.items) && props.products?.items[0] ? props.products?.items[0] : {};
		});

		const orderColor = computed(() => props.orderInfo?.color ?? null);

		const hasProductConfigurator = computed(() => {
			return product.value.access_configurator_from && product.value.access_configurator_from.includes('Product page');
		});

		const hasAssortment = computed(() => {
			return product.value.assortment && !props.orderInfo ? true : false;
		});

		const featureCategories = computed(() =>
			!isEmpty(product.value?.feature_categories?.items) ? product.value.feature_categories : null);

		const hasFeatures = computed(() => {
			return featureCategories.value ? true : false;
		});

		const hasReferences = computed(() => {
			return references.value?.length ? true : false;
		});

		const hasAccessories = computed(() => {
            return product.value?.accessory_groups?.filter(e => e.id != null).length ? true : false;
		});

		const productSections = computed(() => {
			return [
				{
					name: useI18n().t('Specifications'),
					scrollTo: '#specifications',
					visible: true,
				},
				{
					name: useI18n().t('Features'),
					scrollTo: '#features',
					visible: hasFeatures.value,
				},
				{
					name: useI18n().t('References'),
					scrollTo: '#references',
					visible: hasReferences.value,
				},
				{
					name: useI18n().t('Assortment'),
					scrollTo: '#assortment',
					visible: hasAssortment.value,
				},
				{
					name: useI18n().t('Accessories'),
					scrollTo: '#accessories',
					visible: hasAccessories.value,
				},
			]
		});

		const productDataIsLoading = computed(() => {
			return props.loading || (productsLoading.value && !productGetters.getName(product.value))
		});

		const productName = computed(() =>
			getProductName(product.value)
		);

		const productShortDescription = computed(() => product.value.short_description?.html || '');
		const productDescription = computed(() => product.value.description?.html || '');
		const productSpecificationDescription = computed(() => product.value.specification_description || '');
		const productNotes = computed(() => product.value.notes);

		const productCategories = computed(() => {
			if (lastVisitedCategoryUrlPath.value) {
				return [findActiveCategory(categoryTree.value, lastVisitedCategoryUrlPath.value)];
			}

			return productGetters.getCategoriesBasedOnUrlPath(product.value, path);
		});

		const breadcrumbs = computed(() => {
			return productGetters.getBreadcrumbs(
				product.value,
				Array.isArray(productCategories.value) ? productCategories?.value[productCategories?.value?.length - 1] : [],
			);
		});

        const productGallery = computed(() => {
            const gallery = productGetters.getGallery(product.value).map((img) => ({
                mobile: { url: hasOneImage.value ? oneImageUrl : img.small },
                desktop: { url: hasOneImage.value ? oneImageUrl : img.normal },
                big: { url: hasOneImage.value ? oneImageUrl : img.big },
                sirv: hasOneImage.value ? null : img.sirv,
                // eslint-disable-next-line no-underscore-dangle
                alt: product.value._name || product.value.name,
                tags: img.tags,
                uid: Math.ceil(Math.random() * 0x10000)
            }));

            if (!hasOneImage.value) {
                return gallery;
            } else {
                return gallery.length > 0 ? [gallery[0]] : [];
            }
        });

        const getGeoCoordinates = () => {
            return new Promise((resolve, reject) => {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            resolve({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                } else {
                    const error = new Error("Geolocation is not supported by this browser.");
                    reject(error);
                }
            });
        };

        const openMapPopup = async() => {
            try {
                showMapPopup.value = true;
                coordinatesFinishedLoading.value = false;
                const coordinates = await getGeoCoordinates();

                if (coordinates) {
                    projectDetails.value.latitude = coordinates.latitude;
                    projectDetails.value.longitude = coordinates.longitude;
                    projectDetails.value.salesOrderNumber = salesOrderNumber;
                    projectDetails.value.article = params.article;
                    projectDetails.value.serialNumber = serialNumber;
                    coordinatesFinishedLoading.value = true;
                }
            } catch (error) {
                console.error("Failed to get coordinates:", error);
            }
        }

		const attributeSet = computed(() => productGetters.getAttributeSet(product.value));
		const hero = computed(() => productGetters.getProductHeroImage(product.value));
		const categoryIds = computed(() => productGetters.getCategoryIds(product.value));
		const productLabel = computed(() => productGetters.getProductLabel(product.value));

		const labels = computed(() => {
			return productLabelsQuery.value?.items || []
		});

		const abbreviation = computed(() =>
			!isEmpty(product.value?.abbreviation) ? product.value.abbreviation : null);

		useAsync(async () => {
			const labelsSearchQuery = {
				filter: {
					product_sku: {
						eq: props.sku
					}
				}
			}

			productLabelsQuery.value = props.sku !== '' ? await getLabels({ ...labelsSearchQuery }) : null;

			const referenceData = await getReferences({
				filter: {
					products: {
						eq: props.sku
					},
				}
			});

			references.value = referenceData.items;

			const tags = [
				{
					prefix: CacheTagPrefix.View,
					value: `product-${product.value.uid}`,
				},
			];

			const productTags = {
				prefix: CacheTagPrefix.Product,
				value: product.value.uid,
			};

			const categoriesTags = categoryIds.value.map((catId) => ({
				prefix: CacheTagPrefix.Category,
				value: catId,
			}));

			addTags(tags.concat(productTags, categoriesTags));
		});

		onMounted(() => {
            hasOneImage.value = path.includes(55109939) ? true : false;
            window.addEventListener('resize', onWidthChange);
        });

		onUnmounted(() => window.removeEventListener('resize', onWidthChange));

		const galleryTemplate = ref(null);

		const goToBigImage = (color) => {
			const bigImageIndex = productGallery.value.findIndex(img => img.tags?.includes(color));

			if (bigImageIndex !== -1) {
				galleryTemplate.value.$refs.bigImagesCarousel.goTo(bigImageIndex);
				galleryTemplate.value.$refs.smallImagesCarousel.$refs.innerSlider.currentSlide = null;
			}
		}

		return {
			breadcrumbs,
			displayWidth,
			product,
			abbreviation,
			productDataIsLoading,
			attributeSet,
			productDescription,
			productGallery,
			getProductName,
			productShortDescription,
			productSections,
			productSpecificationDescription,
			productNotes,
			labels,
			loadingLabels,
			references,
			loadingReferences,
			hero,
			featureCategories,
			...productGetters,
			productName,
			orderColor,
			hasProductConfigurator,
			productsLoading,
			isMobile,
			galleryTemplate,
			goToBigImage,
			productLabel,
            query,
            hasOneImage,
            getGeoCoordinates,
            projectDetails,
            salesOrderNumber,
            serialNumber,
            openMapPopup,
            showMapPopup,
            coordinatesFinishedLoading,
		};
	}
});
</script>
<style lang="scss" scoped>
#product {
	.product-video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;

		embed, iframe, object {
			position: absolute;
			top: 0;
			left: 120px;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	.sections {
		position: sticky;
		top: 70px;
		z-index: 10;
		overflow-x: scroll;
		border-bottom: 1px solid var(--_c-gray-secondary);

		@media (min-width: 576px) {
			overflow-x: unset;
		}

		@media (min-width: 768px) {
			border-bottom: unset;
		}

		@media (min-width: 992px) {
			top: 0;
		}

		&__menu {
			@media (min-width: 768px) {
				border-bottom: 1px solid var(--_c-gray-secondary);
			}
		}

		&__section {
			margin-right: 23px;

			@media (max-width: 992px) {
				font-size: 14px;
			}
		}
	}

	.product {
		&-gallery {
			order: -1;

			@media (min-width: 992px) {
				order: unset;
			}

			::v-deep .sf-loader__overlay {
				top: 50%;
				transform: translateY(-50%);
			}
		}

        &-new-label {
            margin: 0;
            text-align: center;
            color: var(--c-white);
            background: var(--c-dark);
            padding: 16px;
        }
	}

	.min-height-large {
		min-height: none;

		@media (min-width: 992px) {
			min-height: 760px;
		}
	}
}

.hero {
	margin-bottom: 35px;
}

.related-products-slider {
	margin-bottom: 35px;

	::v-deep .project {
		border-top: 1px solid var(--_c-gray-secondary);

		@media (min-width: 992px) {
			margin-bottom: 50px;
		}
	}

	::v-deep h5 {
		font-size: 20px;
		line-height: 28px;
	}
}

::v-deep .svg-image {
	float: right;
}

::v-deep .sf-loader {
	&__overlay {
		position: relative;
	}
}
</style>