var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[(_vm.activeCategory && _vm.activeCategory.name.toLowerCase() == 'brackets')?_c('div',[_c('div',{staticClass:"row product--children"},_vm._l((_vm.specialProducts.items),function(product,index){return _c('div',{key:_vm.getSlug(product),staticClass:"p-0 mb-2 product--children-child",class:_vm.getClass(index, 'main', _vm.specialProducts.items.length)},[_c('nuxt-link',{staticClass:"flex flex-column align-items-center product m-2 interior",class:_vm.getClass(index, 'child', _vm.specialProducts.items.length),attrs:{"to":_vm.localePath(
                        _vm.isProductConfigurator
                            ? _vm.getProductConfigurationLink(product, _vm.getCategory(product, _vm.$route.path, true))
                            : _vm.getProductLink(product, _vm.activeCategory)
                    )}},[_c('div',{staticClass:"hover"},[_c('svg-image',{attrs:{"icon":"arrow-right-thin","width":"50","height":"50"}})],1),_vm._v(" "),_c('nuxt-img',{staticClass:"product--image",attrs:{"src":_vm.getMagentoImage(_vm.getCoverImage(product)),"quality":"100","alt":product.name}}),_vm._v(" "),_c('div',{staticClass:"product--content interior align-self-start"},[(!_vm.isProductConfigurator)?_c('h5',[_vm._v(_vm._s(product.name))]):_c('h5',[_vm._v(_vm._s(product.bee_product_name ? product.bee_product_name.toUpperCase() : product.name))]),_vm._v(" "),(_vm.activeCategory.name.toLowerCase() !== 'brackets')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getProductInfoText(product))}}):_vm._e()])],1)],1)}),0)]):_c('div',[_vm._l((_vm.groups),function(group,index){return (group.products.items.length)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibility),expression:"visibility"}],key:group.uid,ref:"group",refInFor:true,staticClass:"col-12",attrs:{"id":group.uid}},[_c('h2',{class:{ 'hide': _vm.isProductConfigurator }},[_vm._v(_vm._s(group.name)+" "),_c('span',[_vm._v(_vm._s(group.products.items.length))])]),_vm._v(" "),_c('div',{staticClass:"row product--children"},_vm._l((group.products.items),function(product,index){return _c('div',{key:_vm.getSlug(product),staticClass:"p-0 mb-2 product--children-child",class:_vm.getClass(index, 'main', group.products.items.length)},[_c('nuxt-link',{staticClass:"flex flex-column align-items-center product m-2 interior",class:_vm.getClass(index, 'child', group.products.items.length),attrs:{"to":_vm.localePath(
                            _vm.isProductConfigurator
                                ? _vm.getProductConfigurationLink(product, _vm.getCategory(product, _vm.$route.path, true))
                                : _vm.getProductLink(product, _vm.activeCategory)
                        )}},[_c('div',{staticClass:"hover"},[_c('svg-image',{attrs:{"icon":"arrow-right-thin","width":"50","height":"50"}})],1),_vm._v(" "),_c('nuxt-img',{staticClass:"product--image",attrs:{"src":_vm.getMagentoImage(_vm.getCoverImage(product)),"quality":"100","alt":product.name}}),_vm._v(" "),_c('div',{staticClass:"product--content interior align-self-start"},[(!_vm.isProductConfigurator)?_c('h5',[_vm._v(_vm._s(product.name))]):_c('h5',[_vm._v(_vm._s(product.bee_product_name ? product.bee_product_name.toUpperCase() : product.name))]),_vm._v(" "),(_vm.activeCategory.name.toLowerCase() !== 'brackets')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getProductInfoText(product))}}):_vm._e()])],1)],1)}),0)]):_vm._e()}),_vm._v(" "),_c('div',{staticClass:"group-filter"},_vm._l((_vm.groups),function(group,index){return (group.products.items.length)?_c('button',{key:index,staticClass:"button small button-secondary",class:{ active: group.visible },on:{"click":function($event){return _vm.scrollTo(group.uid)}}},[_vm._v("\n            "+_vm._s(group.name)+" "),_c('span',[_vm._v(_vm._s(group.products.items.length))])]):_vm._e()}),0)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }