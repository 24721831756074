<template>
    <div class="row">
        <div v-if="activeCategory && activeCategory.name.toLowerCase() == 'brackets'">
            <div class="row product--children">
                <div
                    v-for="(product, index) in specialProducts.items"
                    :key="getSlug(product)"
                    class="p-0 mb-2 product--children-child"
                    :class="getClass(index, 'main', specialProducts.items.length)"
                >
                    <nuxt-link
                        class="flex flex-column align-items-center product m-2 interior"
                        :class="getClass(index, 'child', specialProducts.items.length)"
                        :to="localePath(
                            isProductConfigurator
                                ? getProductConfigurationLink(product, getCategory(product, $route.path, true))
                                : getProductLink(product, activeCategory)
                        )"
                    >
                        <div class="hover">
                            <svg-image
                                icon="arrow-right-thin"
                                width="50"
                                height="50"
                            />
                        </div>
                        <nuxt-img :src="getMagentoImage(getCoverImage(product))" quality="100" :alt="product.name" class="product--image" />
                        <div class="product--content interior align-self-start">
                            <h5 v-if="!isProductConfigurator">{{ product.name }}</h5>
                            <h5 v-else>{{ product.bee_product_name ? product.bee_product_name.toUpperCase() : product.name }}</h5>
                            <span v-if="activeCategory.name.toLowerCase() !== 'brackets'" v-html="getProductInfoText(product)"></span>
                        </div>
                    </nuxt-link>
                </div>
            </div>
        </div>
        <div v-else>
            <div
                v-for="(group, index) in groups"
                :key="group.uid"
                :id="group.uid"
                class="col-12"
                ref="group"
                v-if="group.products.items.length"
                v-observe-visibility="visibility"
            >
                <h2 :class="{ 'hide': isProductConfigurator }">{{ group.name }} <span>{{ group.products.items.length }}</span></h2>
                <div class="row product--children">
                    <div
                        v-for="(product, index) in group.products.items"
                        :key="getSlug(product)"
                        class="p-0 mb-2 product--children-child"
                        :class="getClass(index, 'main', group.products.items.length)"
                    >
                        <nuxt-link
                            class="flex flex-column align-items-center product m-2 interior"
                            :class="getClass(index, 'child', group.products.items.length)"
                            :to="localePath(
                                isProductConfigurator
                                    ? getProductConfigurationLink(product, getCategory(product, $route.path, true))
                                    : getProductLink(product, activeCategory)
                            )"
                        >
                            <div class="hover">
                                <svg-image
                                    icon="arrow-right-thin"
                                    width="50"
                                    height="50"
                                />
                            </div>
                            <nuxt-img :src="getMagentoImage(getCoverImage(product))" quality="100" :alt="product.name" class="product--image" />
                            <div class="product--content interior align-self-start">
                                <h5 v-if="!isProductConfigurator">{{ product.name }}</h5>
                                <h5 v-else>{{ product.bee_product_name ? product.bee_product_name.toUpperCase() : product.name }}</h5>
                                <span v-if="activeCategory.name.toLowerCase() !== 'brackets'" v-html="getProductInfoText(product)"></span>
                            </div>
                        </nuxt-link>
                    </div>
                </div>
            </div>
            <div class="group-filter">
                <button
                    v-for="(group, index) in groups"
                    :key="index"
                    @click="scrollTo(group.uid)"
                    class="button small button-secondary"
                    :class="{ active: group.visible }"
                    v-if="group.products.items.length"
                >
                {{ group.name }} <span>{{ group.products.items.length }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { productGetters } from '~/getters';
import { useUiState, useImage } from '~/composables';

export default defineComponent({
	name: 'ProductGroupedList',
	props: {
		activeCategory: Object,
		productGroups: Array,
		isProductConfigurator: Boolean,
	},
	methods: {
		getRefItem(id) {
			return this.$refs.group.find((item) => {
				return item.id === id;
			})
		},
		scrollTo (id) {
			this.$nextTick(function () {
				const ref = this.getRefItem(id);

				if (ref) {
					ref.scrollIntoView({ behavior: 'smooth' });
				}
			});
		}
	},
	transition: 'fade',
	setup(props) {
		const { getMagentoImage } = useImage();
		const { isMobile } = useUiState();
		const visibleGroup = ref(null);

		const groups = computed(() => {
			return props.productGroups.map((group) => ({
				...group,
				visible: visibleGroup.value && visibleGroup.value.id == group.uid ? true : false,
			}));
		});

        const specialProducts = computed(() => {
            return props.activeCategory.products;
        })

		const getClass = (index, type, length) => {
			if (isMobile.value) {
				if (length % 7 == 0) {
					if (length - 2 <= index) {
						if (type == 'main') {
							return 'col-6';
						} else {
							return 'rectangle';
						}
					}
				}

				if (length % 9 == 0) {
					if (length - 1 <= index) {
						if (type == 'main') {
							return 'col-12';
						} else {
							return 'square';
						}
					}
				}

				if (type == 'main') {
					return index % 5 == 0 ? 'col-12' : 'col-6';
				} else {
					return index % 5 == 0 ? 'square' : 'rectangle';
				}
			} else {
				if (length % 6 == 0 || length % 11 == 0) {
					if (length - 3 <= index) {
						if (type == 'main') {
							return 'col-md-4';
						} else {
							return 'rectangle';
						}
					}
				}

				if (length < 3 || length % 7 == 0) {
					if (length - 3 <= index) {
						if (type == 'main') {
							return 'col-md-6';
						} else {
							return 'square';
						}
					}
				}

				if (length % 9 == 0) {
					if (length - 4 <= index) {
						if (type == 'main') {
							return 'col-md-6';
						} else {
							return 'square';
						}
					}
				}

				if (type == 'main') {
					return index % 5 == 3 || index % 5 == 4 ? 'col-md-6' : 'col-md-4';
				} else {
					return index % 5 == 3 || index % 5 == 4 ? 'square' : 'rectangle';
				}
			}
		};

		const getProductInfoText = (product) => {
			return product ? productGetters.getProductInfo(product) : '';
		};

		const visibility = (isVisible, entry) => {
			if (isVisible) {
				visibleGroup.value = entry.target;
			}
		}

		return {
			...productGetters,
			getMagentoImage,
			getClass,
			getProductInfoText,
			groups,
			visibility,
			visibleGroup,
            specialProducts
		}
	}
})
</script>

<style lang="scss" scoped>
h2 {
    span {
        font-family: var(--font-family--primary);
    }
}

.product--children {
    padding: 0 calc(15px - 0.5rem);

    &-child {
        position: relative;
        overflow: hidden;

        @media (max-width: 767px) {
            max-width: 100%;
        }

        /* desktop */
        &.col-md-4 {
            aspect-ratio: 0.75;

            a {
                height: calc(100% - 1rem);
                overflow: hidden;
            }
        }

        &.col-md-6 {
            aspect-ratio: 1;

            a {
                height: calc(100% - 1rem);
                overflow: hidden;
            }
        }

        /* mobile */
        &.col-6 {
            aspect-ratio: 0.75;

            a {
                height: calc(100% - 1rem);
                overflow: hidden;
            }
        }
    }
}

.product {
    background: #DBDBDB;

    @media (max-width: 767px) {
        max-width: inherit;
        margin-bottom: 20px;
    }

    .hover {
        background: rgba(0, 0, 0, 0.18);
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        bottom: 0.5rem;
        right: 0.5rem;
        opacity: 0;
        transition: all 0.6s ease-in-out 0s;
        z-index: 1;

        ::v-deep .svg-image {
            position: absolute;
            right: 25px;
            top: 25px;
            color: var(--c-white);
            transition: all 0.6s ease-in-out 0s;
        }
    }

    &:hover {
        .hover {
            opacity: 1;
        }
    }

    &--image {
        width: 100%;
    }

    &--content {
        &.exterior {
            margin-top: auto;

            strong {
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.2px;
                margin: 20px 0 0 0;
                font-family: var(--font-family--secondary);
                display: block;
            }
        }

        &.interior {
            position: absolute;
            top: 1rem;
            left: 1.5rem;
            z-index: 1;
            padding-right: 10px;

            @media (max-width: 767px) {
                top: 0.5rem;
                left: 1rem;
            }
        }

        h5 {
            margin: 15px 0 4px 0;
            font-size: 14px;
            line-height: 14px;

            @media(max-width: 991px) {
                margin-bottom: 0px;
            }
        }

        span {
            display: block;
            font-size: 12px;
            letter-spacing: -0.4px;
        }
    }
}

.group-filter {
    position: fixed;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;

    @media (max-width: 767px) {
        display: none;
    }

    button {
        margin: 0 10px;

        &.active {
            background: var(--c-dark);
            color: var(--c-white);
        }
    }
}

.hide {
        display: none;
    }
</style>
