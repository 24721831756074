<template>
	<div class="row">
		<div class="col-12 col-lg-3">
			<h3 v-if="!showFeatureInMobileView" class="mb-12 mt-3">{{ $t('Features') }}</h3>
			<div v-else class="back mb-52" @click="returnToListView()">
				<svg-image
					icon="arrow_left"
					width="32"
					height="32"
				/>
				<h3>{{ $t('Back') }}</h3>
			</div>
			<h2 class="mb-12" v-if="showFeature && showFeatureInMobileView">
				{{ featureCategoryName }}
			</h2>
			<div class="category-list" :class="{ hide: showFeatureInMobileView }">
				<div
					v-for="(featureCategory, index) of featureCategories.items" :key="featureCategory.id"
					class="list-item"
				>
					<div
						@click="setFeatureCategoryId(featureCategory.id, featureCategory.name, index)"
						class="flex justify-content-between align-items-center pointer w-100"
					>
						<p :class="{ selected: isSelected(featureCategory.id) }">{{ $t(featureCategory.name) }}</p>
						<p class="arrow">
							<svg-image
								icon="arrow-right-hollow"
								width="12"
								height="32"
							/>
						</p>
					</div>
					<div
						v-if="isSelected(featureCategory.id) && !isMobile"
						class="list-item-items flex flex-column align-items-start pl-3"
					>
						<p
							v-for="(feature, index) in features.items"
							:key="feature.entity_id"
							@click="setFeatureIndex(index)"
							class="pointer"
							:class="{ selected: menuSelectedFeatureIndex === index }"
						>
							{{ feature.name }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showFeature" class="col-0 col-lg-9">
			<Feature
				:featureCategoryId="featureCategoryId"
				:categoryFeatures="features"
				:productSku="productSku"
				:showFeatureInMobileView="showFeatureInMobileView"
				:showLast="showLast"
				:menuSelectedFeatureIndex="menuSelectedFeatureIndex"
				@changeCategory="changeCategory"
				@setMenuSelectedFeature="setFeatureIndex"
			/>
		</div>
	</div>
</template>
<script>
import {
	ref,
	defineComponent,
	onMounted,
	computed
} from '@nuxtjs/composition-api';
import { useFeature } from '~/composables/useFeature';
import Feature from './Feature.vue';
import filterDeep from 'deepdash/filterDeep';
import { useUiState } from '~/composables';

export default defineComponent({
	name: 'FeatureCategory',
	components: {
		useFeature,
		Feature,
	},
	transition: 'fade',
	props: {
		featureCategories: {
			type: Object,
			required: true,
			default: () => {},
		},
		productSku: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const featureCategoryId = ref(1);
		const selectedId = ref(0);
		const showFeatureInMobileView = ref(false);
		const showFeature = ref(false);
		const featureCategoryName = ref('');
		const categoryIds = ref([]);
		const currentCategoryIndex = ref(0);
		const showLast = ref(false);
		const menuSelectedFeatureIndex = ref(0);

		const { isMobile } = useUiState();

		const features = computed(() => {
			const features = props.featureCategories.items.map((featureCategory) => {
				let item = featureCategory.features;
				item.items.sort(function (a, b) {
                    return a.order_no - b.order_no;
                });

				return item;
			});

			return filterDeep(
				features,
				(value, key, parentValue) => parseInt(parentValue.category) === featureCategoryId.value
			)[0] ?? {};
		});

		const setFeatureCategoryId = (id, name, index) => {
			menuSelectedFeatureIndex.value = 0;
			currentCategoryIndex.value = index;
			featureCategoryId.value = id;
			showFeature.value = true;
			featureCategoryName.value = name;
			showLast.value = false;
			showFeatureInMobileView.value = isMobileView();
		}

		const setFeatureIndex = (index) => {
			if (index !== menuSelectedFeatureIndex.value) {
				menuSelectedFeatureIndex.value = index;
			}
		}

		const isSelected = (id) => {
			return id == featureCategoryId.value;
		}

		const returnToListView = () => {
			showFeature.value = false;
			showFeatureInMobileView.value = false;
		}

		const isMobileView = () => {
			const mediaQuery = window.matchMedia('(min-width: 994px)');
			return !mediaQuery.matches;
		}

		const changeCategory = (value) => {
			currentCategoryIndex.value += value;
			showLast.value = value > 0 ? false : true;
			const selectedIndex = categoryIds.value[currentCategoryIndex.value];

			if (typeof selectedIndex !== 'undefined') {
				featureCategoryId.value = selectedIndex;
			} else {
				currentCategoryIndex.value = 0;
				featureCategoryId.value = categoryIds.value[0];
			}
		}

		onMounted(async () => {
			categoryIds.value = props.featureCategories.items.map(item => item.id);
			featureCategoryId.value = categoryIds.value[0];
			showFeature.value = !isMobileView();
		});

		return {
			featureCategoryId,
			selectedId,
			showFeatureInMobileView,
			showFeature,
			featureCategoryName,
			categoryIds,
			currentCategoryIndex,
			showLast,
			isSelected,
			setFeatureCategoryId,
			setFeatureIndex,
			returnToListView,
			changeCategory,
			features,
			menuSelectedFeatureIndex,
			isMobile
		};
	},
});
</script>

<style lang="scss" scoped>
@import "assets/helpers/breakpoints";

.border-top-1 {
	border-top: 1px solid black;
}

.category-list {
	font-weight: bold;

	@media (min-width: 992px) {
		line-height: 20px;
		font-size: 16px;
		color: var(--c-text-muted);
	}

	.list-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid var(--c-light);

		&-items {
			visibility: hidden;
		}

        p {
            @media (max-width: 991px) {
                margin: 10px 0;
            }
        }

		@include for-desktop() {
			border-bottom: none;
			flex-direction: column;
			align-items: flex-start;
			justify-content: unset;

			.arrow {
				display: none;
			}

			&-items {
				visibility: visible;
			}
		}
	}
}

.mb-12 {
	margin-bottom: 32px;
}

.mb-52 {
	margin-bottom: 52px;
}

.selected {
	color: var(--c-black);
}

.hide {
	display: none;
}

.back {
	margin-top: 38px;
	display: flex;
	align-items: center;

	:first-child {
		margin-right: 10px;
	}
}
</style>