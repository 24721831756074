<template>
    <div class="row" v-if="items && items.length">
        <a name="Registered products"></a>
        <div class="col-12">
            <h3 class="assortment-title pt-5">{{ $t('Registered luminaires') }}</h3>
        </div>
        <vue-good-table
            ref="sortment"
            :columns="columnsData"
            :rows="items"
            compactMode
            :pagination-options="config.paginationOptions"
            :search-options="config.searchOptions"
        >
            <template slot="table-column" slot-scope="props">
                <span>
                    {{ props.column.label }}
                    <svg-image
                        class="arrow"
                        height="14"
                        icon="arrow-right-short"
                        width="14"
                    />
                    <strong v-if="props.column.abbreviation">{{ props.column.abbreviation }}</strong>
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
import { defineComponent, useContext, reactive, ref, watchEffect } from '@nuxtjs/composition-api';
import MultiRangeSlider from '~/components/Common/MultiRangeSlider.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import { useVizulo } from '~/composables';
import Button from '~/components/Common/Button.vue';
import { SfInput, SfModal, SfLoader } from '@storefront-ui/vue';
import { ValidationObserver } from 'vee-validate';
import HTMLContent from '~/components/HTMLContent';
import DatasheetDownloadModal from '~/components/Datasheet/DatasheetDownloadModal';
import { search, pagination } from '~/helpers/table';

export default defineComponent({
	name: 'RegisteredLuminaires',
	components: {
		DatasheetDownloadModal,
		MultiRangeSlider,
		VueGoodTable,
		Button,
		SfInput,
		SfModal,
		ValidationObserver,
		HTMLContent,
		SfLoader,
	},
	props: {
		salesOrderNumber: {
			type: String,
			required: true,
			default: null,
		},
		loading: {
			type: Boolean,
			required: false,
			default: null,
		},
	},
	setup(props) {
		const { app } = useContext();
		const { getApiUrl } = useVizulo();
		const apiUrl = getApiUrl();
		const sortment = reactive({});
		const items = ref([]);
		const wall = ref([]);
        const columnsData = ref([
            { label: 'Sales order number', field: 'sales_order_number' },
            { label: 'Article', field: 'article' },
            { label: 'Serial number', field: 'serial_number' },
            { label: 'Latitude', field: 'latitude' },
            { label: 'Longitude', field: 'longitude' },
            {
                label: 'Created at',
                field: 'created_at',
                formatFn: (value) => {
                    if (!value) return '';
                    return new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(value));
                }
            }
        ]);

        watchEffect(() => {
            if (props.salesOrderNumber) {
                axios({
                    method: 'GET',
                    url: apiUrl + 'registeredLuminaires?salesOrderNumber=' + props.salesOrderNumber,
                }).then((res) => {
                    if (res.data) {
                        items.value = Object.values(res.data.data);
                    }
                });
            }
        });

		const config = ref({
			columnWidth: 360,
			gap: 15,
			rtl: false,
			ssrColumns: 1,
			paginationOptions: pagination(5),
			searchOptions: {
				enabled: true,
				placeholder: app.i18n.t('Search...'),
				skipDiacritics: true,
				searchFn: search
			},
		});

		return {
			config,
			apiUrl,
			sortment,
			columnsData,
			wall,
			items,
		}
	}
})
</script>

<style lang="scss" scoped>
.assortment-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    & + p {
        margin: 0 0 35px 0;
        font-size: 14px;
    }
}

#assortment {
    .sf-loader__overlay {
        margin: 0 auto;
    }
}

.reset {
    float: right;
    border: none;
    background: none;
    display: none;
    position: absolute;
    right: 0;
    top: 72px;

    &.active {
        display: block;
    }
}

::v-deep .vgt-input {
    @media (min-width: 991px) {
        width: 480px;
        max-width: 480px;
    }
}

::v-deep .vgt-wrap {
    width: 100%;
    padding: 0 15px;
}

::v-deep .vgt-responsive {
    @media (max-width: 991px) {
        padding: 0 15px;
    }
}

::v-deep table.vgt-table {
    border: none;
    width: 100% !important;
    border-collapse: separate;
    border-spacing: 0;

    thead {
        th {
            background: none;
            border: none;
            padding: 15px 15px 20px 0;
            vertical-align: top;

            span {
                float: left;
                font-size: 12px;
                line-height: 12px;
                font-family: var(--font-family--primary-bold);
                position: relative;

                .arrow {
                    margin-left: -15px;
                    padding-left: 0;
                }

                strong {
                    position: absolute;
                    left: 0;
                    bottom: -16px;
                    color: #737373;
                    font-family: var(--font-family--primary);
                    font-weight: normal;
                    padding-left: 15px;
                }
            }

            &.sortable {
                span {
                    span {
                        left: 14px;
                        top: 15px;
                        position: absolute;
                        transform: rotate(90deg);
                    }
                }

                &.sorting-desc {
                    span {
                        span {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }

            &:not(.sortable) {
                span {
                    span {
                        display: none;
                    }
                }
            }

            &.sortable::before,
            &.sortable::after {
                display: none;
            }

            .svg-image {
                float: none;
                margin-left: 8px;
            }
        }

        tr:nth-child(2) {
            display: none;
        }

        @media (max-width: 991px) {
            display: none;
        }
    }

    tbody {
        tr {
            &:first-child {
                td {
                    border-top: 1px solid #EAEAEA;

                    @media (max-width: 991px) {
                        border-top: none;
                    }
                }
            }

            @media (max-width: 991px) {
                border: 1px solid var(--c-black);
                padding: 10px;
            }

            td {
                @media (max-width: 991px) {
                    display: block;
                    padding: 8px 0;

                    &:before {
                        padding-left: 0;
                    }

                    &:last-child {
                        @media (max-width: 991px) {
                            border-bottom: none;
                        }
                    }
                }
                i {
                    color: var(--c-light-gray);
                }

            }
        }
    }

    td {
        border: none;
        border-bottom: 1px solid #EAEAEA;
        padding: 15px 15px 15px 0;
        font-size: 14px;

        @media (min-width: 991px) {
            white-space: nowrap;

            &.white-space-normal {
                white-space: normal;
            }
        }

        span {
            span {
                text-decoration: underline;
                display: block;

                &.download-ico {
                    display: inline-block;
                }
            }
        }
    }
}

::v-deep .vgt-global-search {
    background: none;
    border: none;
    text-align: center;

    @media (max-width: 991px) {
        padding: 0 15px;
    }
}

::v-deep .vgt-global-search__input {
    margin: 35px auto 25px;

}

::v-deep .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
}


::v-deep .vgt-wrap__footer {
    background: none;
    border: none;
    text-align: center;

    .footer__navigation {
        float: none !important;

        .chevron {
            display: none;
        }
    }

    .footer__navigation__page-info__current-entry {
        margin: 0 4px;
    }
}

::v-deep .vgt-inner-wrap {
    border: none;
    box-shadow: none;
}

::v-deep .vgt-wrap__footer {
    .footer__row-count {
        display: none;
    }

    .footer__row-count__label {
        font-size: 14px;
    }

    .footer__row-count__select {
        font-size: 14px;
    }

    .footer__navigation__page-info {
        font-size: 14px;
        margin: 0 20px;
    }

    .footer__navigation__page-btn span {
        font-size: 14px;
    }

    .footer__navigation > button {
        margin: 0;
        padding: 10px;
    }

    .footer__navigation__page-btn .chevron.right::after {
        border-left: 6px solid var(--c-black);
    }

    .footer__navigation__page-btn .chevron.left::after {
        border-right: 6px solid var(--c-black);
    }
}

::v-deep .sf-bar {
    background: white;
}

::v-deep .vgt-right-align {
    text-align: left;
}

</style>